<template>
  <h6 class="link">معلومات المركبة</h6>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">نوع المركبة</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.manufacturer }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="manufacturer"
          :options="manufacturers"
          :selected="manufacturer_id"
          title="نوع المركبة"
          :empty-option="false"
          @changed="setModelsList"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">
        <abbr class="font-weight-bolder text-primary" title="يرجى تحديد نوع المركبة">موديل المركبة</abbr>
      </div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.model }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="model"
          :options="models"
          :selected="model_id"
          title="موديل المركبة"
          :empty-option="false"
          :empty-disabled="true"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">شركة التأمين</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.company }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="company_id"
          :options="companies"
          :selected="auction.company_id"
          title="شركة التأمين"
          :empty-option="false"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">سنة الصنع</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.year }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="year"
          title="سنة الصنع"
          :options="auctionYears"
          :selected="auction.year"
          :empty-option="false"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">آلية الحركة</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.movement_mechanism }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="movement_mechanism"
          title="آلية الحركة"
          :options="movementMechanisms"
          :selected="auction.movement_mechanism"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">ناقل الحركة</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.movement_vector }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="movement_vector"
          title="ناقل الحركة"
          :options="movementVectors"
          :selected="auction.movement_vector"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">اللون</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.color }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="color"
          title="اللون"
          :options="colors"
          :selected="auction.color"
          :empty-option="false"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">المفتاح</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.car_key }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="car_key"
          title="المفتاح"
          :options="carKeys"
          :selected="auction.car_key"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">المزاود الآلي</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ autoBidText }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="auto_bid"
          title="المزاود الآلي"
          :options="bidsStatus"
          :selected="auction.auto_bid || 'y'"
          :empty-option="false"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">نقل المركبة</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ transferredText }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="transferred"
          title="نقل المركبة"
          :options="transferred"
          :selected="auction.transferred || 'n'"
          :empty-option="false"
          @changed="setIsTransferred"
        />
      </div>
    </div>
  </div>
  <div class="row" v-if="isTransferred">
    <div class="col-md-6 row">
      <div class="col-md-4">المحافظة</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.city }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="city_id"
          title="المحافظة"
          :options="cities"
          :empty-option="false"
          @changed="setAreas"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">
        <abbr class="font-weight-bolder text-primary" title="يرجى تحديد المحافظة">المنطقة</abbr>
      </div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.area }}</text-secondary>
        <BootstrapSelect
          v-else-if="editing"
          id="area_id"
          title="المنطقة"
          :options="areas"
          :selected="auction.area_id"
          :empty-option="false"
          :empty-disabled="true"
        />
      </div>
    </div>
  </div>
  <div class="row" v-if="isTransferred">
    <div class="col-md-6 row">
      <div class="col-md-4">عنوان الكراج</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.winch_note }}</text-secondary>
        <FormInput
          v-else-if="editing"
          id="winch_note"
          text="عنوان الكراج"
          :value="auction.winch_note"
          :show-label="false"
        />
      </div>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col-md-6 row">
      <div class="col-md-4">اسم الونش</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.winch_note }}</text-secondary>
        <FormInput
          v-else-if="editing"
          id="winch_note"
          text="اسم الونش"
          :value="auction.winch_note"
          :show-label="false"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">رقم الشاصي</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.vin_no }}</text-secondary>
        <FormInput
          v-else-if="editing"
          id="vin_no"
          text="رقم الشاصي"
          :value="auction.vin_no"
          :show-label="false"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">الوقود</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.oil }}</text-secondary>
        <BootstrapSelect
          id="oil"
          title="الوقود"
          v-else-if="editing"
          :options="oils"
          :selected="auction.oil"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">العداد</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.traveled }}</text-secondary>
        <FormInput
          v-else-if="editing"
          id="traveled"
          text="العداد"
          :value="auction.traveled"
          :show-label="false"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">رقم اللوحة</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.plate_no }}</text-secondary>
        <FormInput
          id="plate_no"
          text="رقم اللوحة"
          v-else-if="editing"
          :value="auction.plate_no"
          :show-label="false"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">رقم الإدعاء</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.claim_no }}</text-secondary>
        <FormInput
          v-else-if="editing"
          id="claim_no"
          text="رقم الإدعاء"
          :value="auction.claim_no"
          :show-label="false"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="col-md-4">مرخصة لغاية</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ licensed }}</text-secondary>
        <FormInput
          id="licensed"
          type="date"
          v-else-if="editing"
          :value="auction.licensed"
          text="مرخصة لغاية"
          :show-label="false"
        />
      </div>
    </div>
    <div class="col-md-6 row">
      <div class="col-md-4">سعة المحرك</div>
      <div class="col-md-8">
        <text-secondary v-if="!editing && validAuction">{{ auction.engine_capacity }}</text-secondary>
        <FormInput
          id="engine_capacity"
          type="number"
          v-else-if="editing"
          :value="auction.engine_capacity"
          text="سعة المحرك"
          :show-label="false"
        />
      </div>
    </div>
  </div>
  <hr>
  <h6>
    <span class="link">معلومات المزاد</span>&nbsp;
    <small v-if="dateFinished && isApproved" class="font-weight-bolder text-danger">( لا يمكن التعديل في حال إنتهاء المزاد )</small>
  </h6>
  <div>
    <div class="row">
      <div class="col-md-6 row">
        <div class="col-md-4">الحد الأدنى للبيع</div>
        <div class="col-md-8">
          <text-secondary v-if="cannotUpdateAuctionTime || !editing">{{ auction.min_price }}</text-secondary>
          <FormInput
            v-else-if="editing"
            id="min_price"
            type="number"
            :value="auction.min_price"
            text="الحد الأدنى للبيع"
            :show-label="false"
          />
        </div>
      </div>
      <div class="col-md-6 row">
        <div class="col-md-4">أقل قيمة للمزاودة</div>
        <div class="col-md-8">
          <text-secondary v-if="cannotUpdateAuctionTime || !editing">{{ auction.lowest_bid }}</text-secondary>
          <BootstrapSelect
            v-else-if="editing"
            id="lowest_bid"
            title="أقل قيمة للمزاودة"
            :options="lowestBids"
            :selected="auction.lowest_bid"
            :empty-option="false"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 row">
        <div class="col-md-4">دقائق الزيادة</div>
        <div class="col-md-8">
          <text-secondary v-if="cannotUpdateAuctionTime || !editing">{{ auction.extra_minutes }}</text-secondary>
          <FormInput
            v-else-if="editing"
            id="extra_minutes"
            type="number"
            :value="extraMinutes"
            min="1"
            text="دقائق الزيادة"
            :show-label="false"
          />
        </div>
      </div>
      <div class="col-md-6 row">
        <div class="col-md-4">سعر الإفتتاح</div>
        <div class="col-md-8">
          <text-secondary v-if="cannotUpdateAuctionTime || !editing">{{ auction.opening_price }}</text-secondary>
          <FormInput
            v-else-if="editing"
            id="opening_price"
            text="سعر الإفتتاح"
            type="number" :value="auction.opening_price || 100"
            :show-label="false"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 row">
        <div class="col-md-4">تاريخ البدء</div>
        <div class="col-md-8">
          <text-secondary v-if="cannotUpdateAuctionTime || !editing">{{ startDate }}</text-secondary>
          <FormInput
            v-else-if="editing"
            id="start_date"
            text="تاريخ البدء"
            type="datetime-local"
            @changed="checkDate"
            :show-label="false"
          />
        </div>
      </div>
      <div class="col-md-6 row" v-if="superAdmin">
        <div class="col-md-4">تاريخ الإنتهاء</div>
        <div class="col-md-8">
          <text-secondary v-if="cannotUpdateAuctionTime || !editing" v-html="finishDate"></text-secondary>
          <FormInput
            v-else-if="editing"
            id="finish_date"
            text="تاريخ الإنتهاء"
            type="datetime-local"
            @changed="checkDate"
            :show-label="false"
          />
        </div>
      </div>
    </div>
    <div class="row" v-if="!creating">
      <div class="col-md-6 row">
        <div class="col-md-4">سعر البيع</div>
        <div class="col-md-8">
          <text-secondary>
            <span v-if="dateFinished">{{ currentPrice }}</span>
            <span v-else>لم ينتهي المزاد</span>
          </text-secondary>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TextSecondary from "@/components/UI/TextSecondary";
import FormInput from "@/components/Forms/FormInput";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import cars from "@/mixins/cars";
import companies from "@/mixins/companies";
import random from "@/common/random";
import date from "@/common/dateMethods";
import errors from "@/mixins/errors";
import types from "@/common/types";

export default {
  components: {BootstrapSelect, FormInput, TextSecondary},
  mixins: [cars, companies, errors],
  emits: ['models-changed'],
  props: {
    auction: {default: {}},
    editing: {default: true},
  },
  data() {
    return {
      isTransferred: false,
      manufacturer_id: '',
      model_id: '',
    }
  },
  computed: {
    ...mapGetters([
      'shouldReload', 'movementMechanisms', 'movementVectors', 'colors', 'superAdmin',
      'carKeys', 'bidsStatus', 'transferred', 'cities', 'areas', 'oils', 'lowestBids'
    ]),
    validAuction() {
      return types.isObject(this.auction);
    },
    auctionYears() {
      return random.list(new Date().getFullYear(), {future: 2})
    },
    dateFinished() {
      return !this.auction || this.auction.is_finished
    },
    isApproved() {
      return this.validAuction && types.checkY(this.auction.approved)
    },
    cannotUpdateAuctionTime() {
      return this.dateFinished && this.isApproved
    },
    creating() {
      return this.$route.name === 'auctions.create';
    },
    currentPrice() {
      return !this.validAuction || !this.auction.current_price ? 'غير محدد' : (this.auction.current_price + ' دينار')
    },
    finishDate() {
      return this.validAuction ? date.withTime(this.auction.finish_date) : '';
    },
    startDate() {
      return this.validAuction ? date.withoutTime(this.auction.start_date, true) : '';
    },
    extraMinutes() {
      return this.validAuction ? parseInt(this.auction.extra_minutes) : 2;
    },
    autoBidText() {
      return this.validAuction && types.checkY(this.auction.auto_bid) ? 'مفعل' : 'غير مفعل';
    },
    transferredText() {
      return this.validAuction && types.checkY(this.auction.transferred) ? 'ونش المزاد' : 'ونش خارحي';
    },
    licensed() {
      return !!this.auction ? date.withoutTime(this.auction.licensed, true) : '';
    }
  },
  methods: {
    async setAreas(city) {
      await this.$store.dispatch('setAreasAction', {city});
    },
    setIsTransferred() {
      const transferred = $_('#transferred')
      this.isTransferred = (!!transferred && types.checkY(transferred.val())) || (types.checkY(this.auction.transferred))
    }
  },
  async created() {
    await this.$store.dispatch('setColorsAction');
    await this.$store.dispatch('setCitiesAction');

    await this.setManufacturerList()

    if (!!this.auction) {
      const manufacturer = this.getManufacturerByName(this.auction.manufacturer)
      if (!!manufacturer) {
        this.manufacturer_id = manufacturer.id
        await this.setModelsList(this.manufacturer_id)
      }

      const model = this.getModelByName(this.auction.model)
      if (!!model) {
        this.model_id = model.id
      }
    }
    this.setIsTransferred()
  }
}
</script>

<style scoped>
.col-md-6,
.col-md-4,
.col-md-8 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.col-md-6.row {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
